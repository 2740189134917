:root {
  --main-red: #e21e29;
  --second-red: #e84b54; /*#ff7e3e*/
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block
}

body {
    line-height: 1
}

ol,ul {
    list-style: none
}

blockquote,q {
    quotes: none
}

blockquote:before,blockquote:after,q:before,q:after {
    content: "";
    content: none
}
p   {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;

}

table {
    border-collapse: collapse;
    border-spacing: 0
}

.heading,#root>header h1 {
    font-family: montserrat,sans-serif
}

.sans,html .gist .gist-file .gist-meta {
    font-family: pt sans,helvetica neue,Arial,sans-serif
}

.serif,body {
    font-family: "pt serif",Georgia,Times,times new roman,serif
}

.mono,pre,code,tt,p code,li code {
    font-family: Menlo,Monaco,andale mono,lucida console,courier new,monospace
}

a {
    text-decoration: none;
    color: var(--main-red);
}

body {
    line-height: 1.25em;
    color: #000
}

#root>header h1 {
    font-family: pt sans,helvetica neue,Arial,sans-serif;
    font-weight: 700;
    font-size: 5em
}

#root>header h2 {
    font-family: alegreya,"pt serif",serif
}

@media only screen and (min-width: 992px) {
    
}

nav,.pagination,.header-links {
    font-family: montserrat,sans-serif;
    font-weight: 700;
    text-transform: uppercase
}

article h1,article h2,article h3,article h4,article h5,article h6 {
    font-family: montserrat,sans-serif;
    font-weight: 700;
    margin-bottom: .5em;
    margin-top: 1.35em;
    line-height: 1.15em
}

article h1 a,article h2 a,article h3 a,article h4 a,article h5 a,article h6 a {
    color: #000
}

section h1 {
    font-family: montserrat,sans-serif;
    font-weight: 700
}

section h1 a {
    color: #000
}

h1 {
    font-size: 1.7em
}

h2,section h1 {
    font-size: 1.5em
}

h3,section h2,section section h1 {
    font-size: 1.3em
}

h4,section h3,section section h2,section section section h1 {
    font-size: 1em
}

h5,section h4,section section h3 {
    font-size: .9em
}

h6,section h5,section section h4,section section section h3 {
    font-size: .8em
}

.meta {
    font-family: montserrat,sans-serif;
    font-weight: 700
}

p,article blockquote,ul,ol {
    margin-bottom: .75em
}

li {
    margin-bottom: .5em
}

ul {
    list-style-type: circle;
    margin-left: 2em
}

ol {
    list-style-type: decimal;
    margin-left: 2em
}

sup,sub {
    font-size: .75em;
    position: relative;
    display: inline-block;
    padding: 0 .2em;
    line-height: .8em
}

sup {
    top: -.5em
}

sub {
    bottom: -.5em
}

a[rev=footnote] {
    font-size: .75em;
    padding: 0 .3em;
    line-height: 1
}

q {
    font-style: italic
}

q:before {
    content: "\201C"
}

q:after {
    content: "\201D"
}

em,dfn {
    font-style: italic
}

strong,dfn {
    font-weight: 700
}

del,s {
    text-decoration: line-through
}

abbr,acronym {
    border-bottom: 1px dotted;
    cursor: help
}

hr {
    margin-bottom: .2em
}

small {
    font-size: .8em
}

big {
    font-size: 1.2em
}

article blockquote {
    font-style: italic;
    padding-left: 1em;
    border-left: 4px solid rgba(51,51,51,.5)
}

article blockquote cite {
    font-style: italic
}

article blockquote cite a {
    color: #333!important;
    word-wrap: break-word
}

article blockquote cite:before {
    content: '\2014';
    padding-right: .3em;
    padding-left: .3em;
    color: #333
}

@media only screen and (min-width: 992px) {
    #root>header h1{
        color: #1a1a1a;
    }
    article blockquote {
        padding-left:1.5em;
        border-left-width: 4px
    }
}

.pullquote-right:before,.pullquote-left:before {
    padding: 0;
    border: none;
    content: attr(data-pullquote);
    float: right;
    width: 45%;
    margin: .5em 0 1em 1.5em;
    position: relative;
    top: 7px;
    font-size: 1.4em;
    line-height: 1.45em
}

.pullquote-left:before {
    float: left;
    margin: .5em 1.5em 1em 0
}

.force-wrap {
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word
}



pre {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    line-height: 1.45em;
    font-size: 13px;
    margin-bottom: 2.1em;
    padding: .8em 1em;
    color: #1a1a1a;
    overflow: auto
}

p code,li code {
    display: inline-block;
    white-space: no-wrap;
    background-color: #f7f7f7;
    font-size: .8em;
    line-height: 1.5em;
    border: 1px solid #ccc;
    padding: 0 2px;
    margin: -1px 0
}




.highlight,.gist-highlight {
    margin-bottom: 1.8em;
    background: #f7f7f7;
    overflow-y: hidden;
    overflow-x: auto
}

.highlight pre,.gist-highlight pre {
    background: 0 0;
    border: none;
    padding: 0;
    margin-bottom: 0
}

pre::-webkit-scrollbar,.highlight::-webkit-scrollbar,.gist-highlight::-webkit-scrollbar {
    height: .5em;
    background: #c4c4c4
}

pre::-webkit-scrollbar-thumb:horizontal,.highlight::-webkit-scrollbar-thumb:horizontal,.gist-highlight::-webkit-scrollbar-thumb:horizontal {
    background: var(--second-red);
    -webkit-border-radius: 4px;
    border-radius: 4px
}

.highlight code {
    background: #000
}

figure.code {
    background: 0 0;
    padding: 0;
    border: 0;
    margin-bottom: 1.5em
}

figure.code pre {
    margin-bottom: 0
}

figure.code figcaption {
    position: relative
}

figure.code .highlight {
    margin-bottom: 0
}

.code-title,html .gist .gist-file .gist-meta a[href*='#file'],h3.filename,figure.code figcaption {
    text-align: center;
    font-size: 13px;
    line-height: 2em;
    font-weight: 400;
    margin-bottom: 0;
    font-family: Menlo,Monaco,andale mono,lucida console,courier new,monospace;
    background-color: #c4c4c4;
    color: #4d4d4d;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc
}

.download-source,html .gist .gist-file .gist-meta a[href*=raw],figure.code figcaption a {
    position: absolute;
    right: .8em;
    z-index: 1;
    font-size: 13px;
    padding-left: 3em
}

* {
    box-sizing: border-box
}

html {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    background-color: #e8ecf4;
    color: #000;
    /*background-image: url(noise.png)*/
}

body {
    width: 100%;
    height: 100%;
    min-height: 100%
}

#root {
    width: 80%;
    max-width: 65em;
    margin: 0 auto;
    position: relative;
    min-height: 100%;
    height: auto
}

@media screen and (max-width: 865px) {
    #root {
        width:100%
    }
}

#main {
    min-height: 100%;
    height: auto
}

@media screen and (min-width: 531px) {
    #main {
        border-right-width:35px;
        border-right-style: solid;
        border-right-color: var(--main-red);
        padding-left: 35px;
       
    }
}

#content {
    height: auto;
    min-height: 100%
}

#root>header {
    background-color: #fff;
    /*border-top-right-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px; */
    display: block;
    position: relative;
    height: 140px
}

@media screen and (min-width: 531px) {
    #root>header {
        border-left-width:35px;
        border-left-color: var(--main-red);
        border-left-style: solid;
        margin-right: 35px
    }
}

#root>header hgroup {
    padding: 3em 0 2em 2em;
    display: inline-block
}

@media screen and (min-width: 866px) {
    #root>header {
    }

    #root>header hgroup {
        padding: 3em 0 2em 2em
    }
}

@media screen and (max-width: 531px) {
    #root>header hgroup {
        font-size:.85em
    }
}

#root>header .header-links {
    list-style-type: none;
    margin: 0
}

#root>header .header-links a {
    color: #000
}

@media screen and (min-width: 866px) {
    #root>header .header-links {
        text-align:right;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        margin: 1em 35px
    }

    #root>header .header-links li {
        margin: 0;
        padding: 0
    }
}

@media screen and (max-width: 865px) and (min-width:531px) {
    #root>header .header-links {
        display:block;
        position: relative;
        margin-left: 2em;
        padding: .25em 0
    }
  #root>header h1 {
    font-size:4.2em;

  }

  @media screen and (max-width: 450px) {
    #root>header h1 {
        font-size:3em;
    
      }

  }

 /* #root {
        font-size:.85em
  }*/
    #root>header .header-links li {
        display: inline
    }

    #root>header .header-links li+li:before {
        content: " / ";
        margin-right: .25em
    }

    #root>header .header-links span+span:before {
        content: " / ";
        margin-right: .25em
    }
  
}

@media screen and (max-width: 531px) {
    #root>header .header-links {
        display:block;
        position: relative;
        margin-left: 2em;
        padding: .25em 0
    }

    #root>header .header-links li {
        display: inline
    }

    #root>header .header-links li+li:before {
        content: " / ";
        margin-right: .25em
    }

    #root>header .header-links span {
        font-size: 1.15em;
        display: block
    }
}

nav {
    color: #fff;
    background-color: var(--second-red);
    z-index: 0; /*this was 1500*/
    height: 35px;
    padding: .5em 35px
}

@media screen and (max-width: 531px) {
    nav {
        padding:.5em 15px
    }
}

nav li,nav form,nav fieldset,nav ul {
    display: inline
}

nav a {
    color: #fff
}

nav .prev {
    float: left
}

nav .next {
    float: right
}

nav ul {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin: 0
}

nav ul li+li:before {
    content: " |";
    /*content: url(); A LITTLE HEART SHOULD GO HERE*/
    padding: 0 .5em 0 .25em;
    fill: #fff;
    vertical-align: middle
}

.header-nav {
    text-align: right
}

@media screen and (min-width: 531px) {
    .header-nav {
        margin:0 35px;
        clear: both;
        position: relative
    }

    .header-nav:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: -35px;
        top: 0;
        border: 35px solid;
        border-color: var(--second-red) transparent transparent transparent;
      
    }

    .header-nav:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: -35px;
        bottom: 0;
        border: 35px solid;
        border-color: transparent transparent var(--second-red) transparent
    }
}

.post-tags {
    text-align: center
}

.post-tag {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 75%
}

.footer-nav {
    text-align: center
}

@media screen and (min-width: 531px) {
    .footer-nav {
        margin:0 35px;
        clear: both;
        position: relative
    }

    .footer-nav:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: -35px;
        top: 0;
        border: 35px solid;
        border-color: var(--second-red) transparent transparent transparent
    }

    .footer-nav:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: -35px;
        bottom: 0;
        border: 35px solid;
        border-color: transparent transparent var(--second-red) transparent
    }
}

article {
    background-color: #fff;
    padding: 2em 2.5em;
    min-height: 100%;
    height: auto;
    border-bottom-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px
}

article>header {
    border-bottom: 1px #000 solid;
    margin-bottom: 1em
}

article>header h1 {
    margin-bottom: 0;
    margin-top: 0
}

article .meta {
    font-weight: 400
}

@media screen and (max-width: 531px) {
    article a {
        word-break:break-word
    }
}

article ul {
    list-style-type: circle;
    margin-left: 2em
}

article>footer {
    display: block;
    position: relative;
    margin-top: 1.5em;
    padding-top: .5em;
    border-top: 1px #000 solid

}

article>footer .meta {
    position: relative
}

article>footer .meta .basic-alignment {
    display: inline-block;
    width: 50%;
    display: block;
    min-height: 2.5em
}

article>footer .meta .left {
    position: relative;
    left: 0;
    padding-left: 0
}

article>footer .meta .left:before {
    content: "\00ab";
    width: 0;
    height: 0;
    position: absolute;
    left: -.8em
}

article>footer .meta .right {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 0
}

article>footer .meta .right:before {
    content: "\00bb";
    width: 0;
    height: 0;
    position: absolute;
    right: -.3em
}

article+article {
    margin-top: .55em;
    border-top-left-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px
}

article:last-of-type {
    border-bottom-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0
}

#blog-archives h2 {
    color: #82868e
}

#blog-archives article {
    padding: 0 1em;
    margin: 0 1em 0 2.9em;
    color: #82868e;
    position: relative;
    border-top-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0
}

#blog-archives article time {
    text-align: right;
    left: -3em
}

#blog-archives article time .year {
    display: none
}

#blog-archives article footer {
    display: inline;
    border: none
}

#blog-archives article h3 {
    margin-top: 0;
    margin-bottom: .15em;
    font-weight: 400
}

#blog-archives article+article {
    margin-top: .65em;
    padding-top: .65em;
    border-top: 1px #000 solid
}

section {
    background-color: #fff;
    padding: 0 3em 2em
}

#root>footer {
    padding: 1em 2em;
    text-align: center;
    background-color: #fff;
    position: relative;
    bottom: 0;
    border-radius: 0px 0px 10px 0px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px
}

@media screen and (min-width: 531px) {
    #root>footer {
        margin:0 35px
    }
}

#root>footer span {
    display: block
}

span[role=img] {
    padding: 4px;
}
span[role=img].big {
    font-size:20px;
}

img {
    max-width: 100%
}



#profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
    height: inherit;
    grid-template-areas:
    "data img"
    "desc poem"
    "note note";
}


#polaroid {
	background: #f7f7f7;
	display: inline;
	float: left;
	width:220px;
	padding: 10px 10px 35px;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(0,0,0,.3);
	transition: all .15s linear;
	z-index: 0;
	position: relative;
    transform: rotate(4deg);
    border-radius: 2px;
}

#polaroid:after {
	color: #333;
	font-size: 20px;
	content: attr(title);
	position: relative;
	top:15px;
}
#polaroid img { 
	display: block; 
	width: inherit; 
}
#polaroid:hover{
	transform: rotate(0deg);
	transform: scale(1.15);
	z-index:10;
	box-shadow: 0 10px 20px rgba(0,0,0,.4);
}


dl {
    display: flex;
    flex-flow: wrap;
   }
  dt {
   /*flex-basis: 20%;*/
   width: 40%;
    /*padding: 2px 4px;*/
    text-align: right;
    white-space: nowrap;

  }
  dd {
    /*flex-basis: 70%;*/
    width:58%;
    /*flex-grow: 1;*/
    margin-left: 2px;
    padding: 2px 4px;
    
  }

  dt::after {
    content: ":";
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
   /* grid-template-rows: repeat(6, auto-fit);*/
    grid-gap: 1rem;
    grid-auto-flow: dense;
  }
  
  .bdaycards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-template-rows: repeat(auto-fit, 300px);
    grid-auto-flow: dense;
  }

  .card {
    background: #fff;
    padding-top: 0.6em;
    border-radius: 0.6em;
    /*margin: 1em;*/
    overflow: hidden;
    cursor: pointer;
    /*box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);*/
    transition: all ease 200ms;
    max-width:300px;
  }
  
  .card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
  


  .card h4 {
    color:#000;
  }

  .card h5 {
    color: #bbb;
    font-weight: 700;
    font-size: 0.7em;
    letter-spacing: 0.04em;
    margin: 0.8em 0 0 0;
    text-transform: uppercase;
  }
  
  .card-body {
    padding: 0em 0.5em 1em 0.5em;
  }


  .homepage-image{
    float:right;
    margin-top:-85px;
    max-width:30%;
    height:auto;
}

button {
    background-color:var(--second-red);
    font-size: .85rem;
    box-shadow: none;
    border: 0;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
}

.form-inline {
    display:flex; flex-flow: row wrap; justify-content: center;
}
.form-inline input, span {
    padding: 10px;
}

input {
    font-size: .85rem;
}

.form-inline input {
    display:inline;
    margin: 0 10px 0 10px; 
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}
@media (max-width: 800px) {
    .form-inline input {
        margin: 10px 0;
        min-width:100% !important;
    }
    .form-inline {
        flex-direction: column;
        align-items: stretch;
    }
}

/*mobile phone*/
@media screen and (max-width: 450px) {
    #root>header h1 {
        font-size:3em;
      }
    #root>header {
        height: 120px
      }
      #root>header hgroup {
        padding: 3em 0 1em 2em;
      }

      #root>footer {
        border-radius: 0px 0px 0px 0px;
    }
    #profile {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr 1fr 1fr 1fr;
        height: inherit;
        grid-template-areas:
        "data" 
        "img"
        "desc"
        "poem"
        "note";
    }
    #polaroid {
        margin-left: 11%;
        margin-top: 10px;
    }

  }
  div.alert {
      padding-bottom: 10px;
  }

  div.alert > div {
    padding:10px;
    border: 10px solid;
    border-image: conic-gradient(red, yellow, lime, aqua, blue, magenta, red) 1;  }

  img.portrait {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-bottom: 10px;
  }